import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/home';
import Blog from './pages/blog/blog';
import Services from './pages/services/services';
import Invitro from './pages/invitro/invitro';
import Bacterial from './pages/bacterial/bacterial';
import Oncologic from './pages/oncologic/oncologic';
import Biopsia from './pages/biopsia/biopsia';
import Diabet from './pages/diabet/diabet';
import Pets from './pages/pets/pets';
import Doccheck from './pages/doccheck/doccheck';
import Aimedic from './pages/aimedic/aimedic';
import Turkeymedic from './pages/turkeymedic/turkeymedic';
import Chests from './pages/chests/chests';
import Nevroz from './pages/nevroz/nevroz';
import Gastrit from './pages/gastrit/gastrit';
import Prostat from './pages/prostat/prostat';
import Neckcancer from './pages/neckcancer/neckcancer';
import Cistit from './pages/cistit/cistit';
import Stomach from './pages/stomach/stomach';
import Heart from './pages/heart/heart';
import Buasil from './pages/buasil/buasil';

function App() {
  return (
    <Router>
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/services" element={<Services />} />

        <Route path="/blog/invitro" element={<Invitro />} />
        <Route path="/blog/bacterial" element={<Bacterial />} />
        <Route path="/blog/oncologic" element={<Oncologic />} />
        <Route path="/blog/biopsia" element={<Biopsia />} />
        <Route path="/blog/diabet" element={<Diabet />} />
        <Route path="/blog/pets" element={<Pets />} />
        <Route path="/blog/doccheck" element={<Doccheck />} />
        <Route path="/blog/aimedic" element={<Aimedic />} />
        <Route path="/blog/turkeymedic" element={<Turkeymedic />} />
        <Route path="/blog/chests" element={<Chests />} />
        <Route path="/blog/nevroz" element={<Nevroz />} />
        <Route path="/blog/gastrit" element={<Gastrit />} />
        <Route path="/blog/prostat" element={<Prostat />} />
        <Route path="/blog/neckcancer" element={<Neckcancer />} />
        <Route path="/blog/cistit" element={<Cistit />} />
        <Route path="/blog/stomach" element={<Stomach />} />
        <Route path="/blog/heart" element={<Heart />} />
        <Route path="/blog/buasil" element={<Buasil />} />

        


      </Routes>
    </div>
  </Router>
  );
}

export default App;
