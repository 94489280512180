import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './nevroz.scss'
import nevrozblog from '../../images/blog/nevrozblog.svg'

const Nevroz = () => {
  return (
    <Layout>
      <div className='bacterial'>



        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' />
          <p className='blog-main-txt-head-sub'>ინ ვიტრო განაყოფიერება IVF.</p>
        </div>

        <div className='blog-content'>

          <div className='blog-post-start'>
            <img src={nevrozblog} alt='nevrozblog' />
            <div className='blog-post-start-right'>

              <p className='blog-post-start-head'>ინვიტრო განაყოფიერების (IVF) შესახებ</p>
              <p>ინ ვიტრო განაყოფიერება (ე.წ. ხელოვნური განაყოფიერება) უშვილობის მკურნალობის დამხმარე რეპროდუქციული ტექნოლოგიაა (Assisted Reproductive Technologies – ART), რომელიც ქალისა და მამაკაცის სასქესო უჯრედების ორგანიზმის გარეთ შერწყმას გულისხმობს. შედეგად, მიღებული ემბრიონის ქალის საშვილოსნოში გადატანა ხდება.
                ეს დარგი მუდმივად ვითარდება და  დღესდღეობით, ამ მეთოდით უკვე 5 მილიონზე მეტი ბავშვია დაბადებული მსოფლიო მასშტაბით. </p>
            </div>

          </div>

          <p>in-vitro განაყოფიერების განხორციელებამდე ტარდება წყვილის სრული კლინიკო-ლაბორატორიული გამოკვლევა. გამოკვლევა გულისხმობს ჰორმონალურ, იმუნოლოგიურ, ბაქტერიოლოგიურ და ულტრასონოგრაფიულ კვლევებს.
            ინ ვიტრო განაყოფიერება (IVF) შემდეგი ეტაპებისგან შედგება: საკვერცხეების სტიმულაცია; კვერცხუჯრედის ასპირაცია (ამოღება); განაყოფიერება და კულტივაცია. ბოლოს კი ყველაფერი ემბრიონის გადატანით სრულდება, დარჩენილი ემბრიონები კი იყინება, შემდეგ პაციენტმა საჭირო მკურნალობა უნდა გააგრძელოს და ემბრიონის გადატანიდან 10-12 დღის შემდეგ, მას ჩაუტარდეს ორსულობის ტესტი.</p>
          <h3>როდის მივმართოთ ინ ვიტრო განაყოფიერებას?</h3>
          <p>წყვილს, რომელსაც 12 თვის  განმავლობაში რეგულარული დაუცველი სქესობრივი ცხოვრების მიუხედავად, შვილი არ უჩნდება, უნაყოფობის დიაგნოზს უსვამენ. უშვილობის პრობლემა ოდითგანვე არსებობდა, თანამედროვე მედიცინა კი ამ პრობლემას სხვადასხვა მეთოდებით უმკლავდება.დამხმარე რეპროდუქციულ ტექნოლოგიებს შორის ყველაზე
            ეფექტურად მიიჩნევა ინ ვიტრო განაყოფიერება,რომელიც დღეს ფართოდ გამოიყენება ქალისა და მამაკაცის სხვადასხვა გენეზის უნაყოფობის დროს.</p>
        </div>

      </div>

    </Layout>
  )
}

export default Nevroz