import React from 'react'
import Layout from '../../components/layout/layout'
import './home.scss'
import homenatia from '../../images/homenatia.svg'
import Homenatiabg from '../../images/homenatiabg.svg'
import Mhastanesi from '../../images/logos/7mhastanesi.svg'
import acibadem from '../../images/logos/acibadem.svg'
import imperial from '../../images/logos/imperial.svg'
import liww from '../../images/logos/liww.svg'
import MedicalPark from '../../images/logos/logo-medicalPark.svg'
import medicana from '../../images/logos/medicana.svg'
import memorial from '../../images/logos/memorial.svg'
import mkk from '../../images/logos/mkk.svg'
import bariatria from '../../images/news/bariatria.svg'
import chestcorrection from '../../images/news/chestcorrection.svg'
import diabet from '../../images/news/diabet.svg'
import invitro from '../../images/news/invitro.svg'
import oncologic from '../../images/news/oncologic.svg'
import turkeynews from '../../images/news/turkeynews.svg'

import homedoctor from '../../images/homedoctor.svg'
import homeheart from '../../images/homeheart.svg'

import video from '../../images/video/video.svg'
import costvideo from '../../images/video/costvideo.png'
import doctorvideo from '../../images/video/doctorvideo.png'
import phonevideo from '../../images/video/phonevideo.png'
import techvideo from '../../images/video/techvideo.png'

import oncologicDirection from '../../images/directions/oncologic.svg'
import fizioteraphy from '../../images/directions/fizioteraphy.svg'
import ginecologic from '../../images/directions/ginecologic.svg'
import nervology from '../../images/directions/nervology.svg'
import oftalmology from '../../images/directions/oftalmology.svg'
import pediatric from '../../images/directions/pediatric.svg'
import plastic from '../../images/directions/plastic.svg'
import urology from '../../images/directions/urology.svg'



import natiamobile from '../../images/natiamobile.svg'

import homedoctormobile from '../../images/home/homedoctormobile.svg'
import modemedicmobile from '../../images/home/modemedicmobile.svg'



const Home = () => {

  const clinics = [
    { src: acibadem, alt: 'acibadem' },
    { src: Mhastanesi, alt: '7mhast' },
    { src: imperial, alt: '7mhast' },
    { src: liww, alt: 'liww' },
    { src: MedicalPark, alt: '7mhast' },
    { src: medicana, alt: '7mhast' },
    { src: memorial, alt: 'memorial' },
    { src: mkk, alt: 'mkk' },
  ];

  const news = [
    { src: invitro, title: 'ინ ვიტრო განაყოფიერება IVF.' },
    { src: bariatria, title: 'ბარიატრიული ქირურგია (SLEEVE GASTRECTOMY)' },
    { src: oncologic, title: 'ონკოლოგიური დაავადებები.' },
    { src: diabet, title: 'დიაბეტის ტიპები და გამომწვევი მიზეზები.' },
    { src: turkeynews, title: 'თურქეთი მსოფლიოში მეშვიდე ადგილს იკავებს ჯანმრთელობის ტურიზმში.' },
    { src: chestcorrection, title: 'მკერდის კორექცია (მამოპლასტიკა) – გაზრდა, დაპატარავება, აწევა…' },
  ]

  const choose = [
    { src: phonevideo, title: '24/7 მომსახურება', txt: 'ჩვენი გუნდი გაგიწევთ უფასო კონსულტაციას დიაგნოზის გადამოწმებაში, მკურნალობის დაგემვაში, მგზავრობასა და რეაბილიტაციაში.' },
    { src: doctorvideo, title: 'ექიმის არჩევა', txt: 'ექიმის არჩევა მნიშვნელოვანი გადაწყვეტილებაა, რომელიც პირდაპირ აისახება თქვენს ჯანმრთელობასა და კეთილდღეობაზე. ჩვენ გიმარტივებთ ამ პროცესს კვალიფიციურ ექიმებთან თანამშრომლობით. ჩვენი დახმარებით თქვენ შეგიძლიათ მარტივად იპოვოთ ექიმი, რომელიც დააკმაყოფილებს თქვენს სპეციფიკურ საჭიროებებს.' },
    { src: costvideo, title: 'მოწინავე ტექნოლოგიები & მეთოდები', txt: 'ინოვაციური სამედიცინო ტექნოლოგიები & მეთოდები რაც იძლევა ზუსტი დიაგნოსტიკის და მკურნალობის შესაძლებლობას.' },
    { src: techvideo, title: 'მკურნალობის ხარჯების შემცირება', txt: 'თურქეთში ბევრი კლინიკაა მათ შორის არის კარგიც და ცუდიც, ძვირიც და იაფიც, ხარისხიანი და უხარისხო მომსახურეობით. მკურნალობის ღირებულება სხვადასხვა კლინიკაში განსხვავებულია,  ჩვენ გეხმარებით თავი აარიდოთ ზედმეტ ხარჯებს. ' },
  ]

  const directions = [
    { src: oncologicDirection, title: 'ონკოლოგია', },
    { src: ginecologic, title: 'გინეკოლოგია', },
    { src: urology, title: 'უროლოგია', },
    { src: oftalmology, title: 'ოფთალმოლოგია', },
    { src: nervology, title: 'ნევროლოგია', },
    { src: fizioteraphy, title: 'ფიზიოტერაფია', },
    { src: pediatric, title: 'პედიატრია', },
    { src: plastic, title: 'პლასტიკური ქირურგია', },

  ]

  

  return (
    <Layout>
      <div className='home'>

        <div className='home-head-box'>

          <div class="video-section">
            <img src={video} alt='video' className='video-img' />
          </div>



          <div className='home-head-docs-imgs'>
            <div className='home-head-blue-box'>
              <img src={homedoctor} alt='homedoctor' className='homedoctor-img' />
              <img src={modemedicmobile} alt='modemedicmobile' className='modemedicmobile' />
              <p className='patients-number'>80.000+</p>
              <p className='approve-patients'>კმაყოფილი პაციენტი</p>
            </div>

            <div className='home-head-blue-box home-head-blue-box-bottom'>
              <img src={homeheart} alt='homeheart' className='homeheart-img' />
              <img src={homedoctormobile} alt='homedoctormobile' className='homedoctormobile' />
              <p className='patients-number'>6.500+</p>
              <p className='approve-patients'>წარმატებული ოპერაცია</p>
            </div>
          </div>
        </div>

        <div>

          <h2 className='choose-us'> რატომ გვირჩევენ ჩვენ?</h2>
          <div className='main-services-box-container'>
            {choose.map((item) => (
              <div className='main-services-box'>
                <img src={item.src} />

                <div className='main-services-box-bottom'>
                  <p className='main-services-box-title'>{item.title} </p>
                  <p className='main-services-box-txt'> {item.txt}</p>
                </div>
              </div>
            ))}
          </div>

        </div>

        <div className='home-about-container'>
          <h3 className='your-health'>შენი ჯანმრთელობა შენს  ხელშია !</h3>
          <div className='home-about-container-child'>


            <div className='home-about-box-right '>
              <img src={homenatia} alt='homenatia' className='homenatia-img' />
              <img src={Homenatiabg} alt='Homenatiabg' className='homenatiabg' />
              <p className='natia-name desktop-natia'>ნათია ახალაძე</p>
              <p className='natia-profession desktop-natia'>გენერალური დირექტორი</p>
            </div>
            <div className='natiamobile-box'>
              <img src={natiamobile} alt='natiamobile' className='natiamobile' />
              <p className='natia-name'>ნათია ახალაძე</p>
              <p className='natia-profession'>გენერალური დირექტორი</p>
            </div>
            <div className='home-about-box'>
              <p className='home-about-txt home-about-txt-main'>კომპანია [Medical Tourism] იძლევა უნიკალურ შესაძლებლობას ქართული მოქალაქეებისთვის, მიიღონ უმაღლესი ხარისხის სამედიცინო მომსახურება თურქეთის წამყვან კლინიკებში.</p>

              <p className='home-about-txt'>  ჩვენ გვაქვს მჭიდრო თანამშრომლობა და ურთიერთობა თითოეულ სამედიცინო დაწესებულებასთან, რომლებიც უზრუნველყოფენ ყველაზე მნიშვნელოვან და რთულ სამედიცინო მომსახურებას თანამედროვე ინოვაციური მეთოდებით.
                კომპანია [Medical Tourism] არის ლიდერი სამედიცინო ტურიზმის სფეროში, რომელიც გთავაზობთ პროფესიონალურ და ხარისხიან მომსახურებას საქართველოს მოქალაქეებს, რომლებსაც სურთ მიიღონ წამყვან სამედიცინო კლინიკებში მკურნალობა თურქეთში.
                ჩვენ ვთავაზობთ სრული სამედიცინო ტურიზმის მომსახურების პაკეტს, რომელიც მოიცავს არა მხოლოდ მაღალი ხარისხის მკურნალობას, არამედ ორგანიზაციული საკითხების სრულ პაკეტს – თარჯიმანი, ტრანსპორტი, განთავსება და სხვა.</p>
              <p className='home-about-txt'>ჩვენი მიზანია, რომ თითოეულ პაციენტს მივაწოდოთ საუკეთესო სამედიცინო მომსახურეობა, დავარწმუნოთ მათ კომფორტსა და უსაფრთხოებაში, ხოლო პროცედურები შევასრულოთ უმაღლეს საერთაშორისო სტანდარტებზე. </p>
            </div>
          </div>
        </div>



        <p className='partners-title'>MEDİCAL TOURİSM-ში ჩართული პარტნიორები.</p>

        <div className='clinic-container-imgs-box'>
          {clinics.map((clinic, index) => (
            <div className='clinic-container-imgs'>
              <img src={clinic.src} alt={clinic.alt} key={index} />
            </div>
          ))}

        </div>

        <h4>პოპულალური  მიმართულებები.</h4>
<div className='directions-container'>

        {directions.map((item) => (
          <div>
            <div className='direction-items'>
              <img src={item.src} />
            </div>
            <div className='direction-items-bottom'>
              <p>{item.title}</p>
            </div>
          </div>

        ))}
        </div>


        <p className='home-news'>სიახლეები</p>

        <div className='news-container'>
          {news.map((news, index) => (
            <div>
              <img src={news.src} alt={news.src} />
              <p className='news-container-txt'>{news.title}</p>
            </div>
          ))}
        </div>


      </div>
    </Layout>
  )
}

export default Home