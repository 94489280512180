import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './bacterial.scss'
import bacterialblog from '../../images/blog/bacterialblog.svg'

const Bacterial = () => {
  return (
    <Layout>
      <div className='bacterial'>



        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' />
          <p className='blog-main-txt-head-sub'>ინ ვიტრო განაყოფიერება IVF.</p>
        </div>

        <div className='blog-content'>

          <div className='blog-post-start'>
            <img src={bacterialblog} alt='bacterialblog' />
            <div className='blog-post-start-right'>

              <p className='blog-post-start-head'>ბარიატრიული ქირურგია (SLEEVE GASTRECTOMY)</p>
              <p>სიმსუქნის პრობლემის გადაჭრა ქირურგიული გზით.</p>
              <p>ინ ვიტრო განაყოფიერება (ე.წ. ხელოვნური განაყოფიერება) უშვილობის მკურნალობის დამხმარე რეპროდუქციული ტექნოლოგიაა (Assisted Reproductive Technologies – ART), რომელიც ქალისა და მამაკაცის სასქესო უჯრედების ორგანიზმის გარეთ შერწყმას გულისხმობს. შედეგად, მიღებული ემბრიონის ქალის საშვილოსნოში გადატანა ხდება.
                ეს დარგი მუდმივად ვითარდება და  დღესდღეობით, ამ მეთოდით უკვე 5 მილიონზე მეტი ბავშვია დაბადებული მსოფლიო მასშტაბით. </p>
            </div>

          </div>

          <p>Laparoscopic Sleeve Gastrectomy (LSG) - უსაფრთხო და ნაკლებად რთული, მინი ინვაზიური და შეუქცევადი ოპერაციაა, რომლის დროსაც:</p>
          <ul>
            <li>მილის ფორმის კუჭი ფორმირდება;</li>
            <li>აღარ გამომუშავდება შიმშილის ჰორმონი - გრელინი;</li>
            <li>მილის ფორმის კუჭი ფორმირდება;</li>
            <li>მილის ფორმის კუჭი ფორმირდება;</li>
            <li>მილის ფორმის კუჭი ფორმირდება;</li>
          </ul>
          <h3>როდის მივმართოთ ინ ვიტრო განაყოფიერებას?</h3>
          <p>წყვილს, რომელსაც 12 თვის  განმავლობაში რეგულარული დაუცველი სქესობრივი ცხოვრების მიუხედავად, შვილი არ უჩნდება, უნაყოფობის დიაგნოზს უსვამენ. უშვილობის პრობლემა ოდითგანვე არსებობდა, თანამედროვე მედიცინა კი ამ პრობლემას სხვადასხვა მეთოდებით უმკლავდება.დამხმარე რეპროდუქციულ ტექნოლოგიებს შორის ყველაზე
            ეფექტურად მიიჩნევა ინ ვიტრო განაყოფიერება,რომელიც დღეს ფართოდ გამოიყენება ქალისა და მამაკაცის სხვადასხვა გენეზის უნაყოფობის დროს.</p>
        </div>

      </div>

    </Layout>
  )
}

export default Bacterial